import { CompatibilityLevel } from "./Compatibility.type";

export const SYNERGIES_PARTNERS_SCORE = 76;
export const SUPPORTIVE_COLLABORATORS_SCORE = 51;
export const GROWTH_ALLIES_SCORE = 26;

export const COMPATIBILITY_COLORS = {
  [CompatibilityLevel.SynergiesPartners]: "#336de2",
  [CompatibilityLevel.SupportiveCollaborators]: "#33337f",
  [CompatibilityLevel.GrowthAllies]: "#aca37c",
  [CompatibilityLevel.ConflictProne]: "#c68252",
  [CompatibilityLevel.Yourself]: "#ececec",
};
