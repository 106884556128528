import { FC } from "react";
import {
  Page,
  View,
  Image,
  Document,
  StyleSheet,
  Text,
} from "@react-pdf/renderer";
import Logo from "../../../assets/media/logo-pdf.png";
import Background from "../../../assets/media/fingerprint.svg";
import { CompatibilitySection } from "../CompatibilitySection/CompatibilitySection";
import {
  CompatibilityDetails,
  CompatibilityLevel,
} from "../Compatibility.type";
import { getUsersByCompatibility } from "../Compatibility.fn";
import {
  COMPATIBILITY_COLORS,
  GROWTH_ALLIES_SCORE,
  SUPPORTIVE_COLLABORATORS_SCORE,
  SYNERGIES_PARTNERS_SCORE,
} from "../Compatibility.const";

type Props = {
  img: string;
  userName: string;
  users: CompatibilityDetails[];
};

const styles = StyleSheet.create({
  h1: {
    fontSize: "20pt",
    lineHeight: "0pt",
    color: "#3C78E6",
  },

  p: {
    marginTop: 10,
    color: "white",
    fontSize: 12,
    lineHeight: 1.4,
  },

  relativePage: {
    position: "relative",
    padding: "20px",
    backgroundColor: "#1a1a42",
  },

  absoluteView: {
    position: "absolute",
    width: "160%",
    left: "0",
    top: "0",
    zIndex: "20",
  },

  user: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    borderRadius: 2,
    padding: "4px 6px",
    paddingRight: 0,
    marginTop: 4,
    fontSize: 10,
    color: "white",
  },

  subTitle: {
    marginTop: 10,
    fontSize: 12,
    color: "#336de2",
  },
});

export const CompatibilityPDF: FC<Props> = ({ img, userName, users }) => {
  const synergisPartners = getUsersByCompatibility(
    users,
    (u) => u.score >= SYNERGIES_PARTNERS_SCORE
  );

  const supportiveCollaborators = getUsersByCompatibility(
    users,
    (u) =>
      u.score >= SUPPORTIVE_COLLABORATORS_SCORE &&
      u.score < SYNERGIES_PARTNERS_SCORE
  );

  const growthAllies = getUsersByCompatibility(
    users,
    (u) =>
      u.score >= GROWTH_ALLIES_SCORE && u.score < SUPPORTIVE_COLLABORATORS_SCORE
  );

  const conflictProne = getUsersByCompatibility(
    users,
    (u) => u.score < GROWTH_ALLIES_SCORE
  );

  const data = [
    { title: "Synergies Partners", users: synergisPartners },
    { title: "Supportive Collaborators", users: supportiveCollaborators },
    { title: "Growth Allies", users: growthAllies },
    { title: "Conflict Prone", users: conflictProne },
  ];

  function getBorderColor(title: string) {
    if (title === "Synergies Partners") {
      return COMPATIBILITY_COLORS[CompatibilityLevel.SynergiesPartners];
    }

    if (title === "Supportive Collaborators") {
      return COMPATIBILITY_COLORS[CompatibilityLevel.SupportiveCollaborators];
    }

    if (title === "Growth Allies") {
      return COMPATIBILITY_COLORS[CompatibilityLevel.GrowthAllies];
    }

    return COMPATIBILITY_COLORS[CompatibilityLevel.ConflictProne];
  }

  return (
    <Document>
      <Page style={styles.relativePage}>
        <View style={{ width: "10%", height: "5%" }}>
          <Image src={Logo} />
        </View>

        <View>
          <Text style={styles.p}>{userName}</Text>
          <Text style={styles.h1}>Compatibility Report</Text>
        </View>

        <View
          style={{
            display: "flex",
            // flex: 1,
            flexDirection: "row",
            // flexGrow: 1,
          }}
        >
          <View style={{ width: "50%" }}>
            <Image src={img} />
          </View>

          <View style={{ width: "50%" }}>
            {data.map((d) => (
              <View>
                <View>
                  <Text style={styles.subTitle}>{d.title}</Text>
                </View>
                <View>
                  {d.users?.map((u) => {
                    return (
                      <View
                        key={u.id}
                        style={{
                          ...styles.user,
                          borderLeft: `2px solid ${getBorderColor(d.title)}`,
                        }}
                      >
                        <Text>{u.name}</Text>
                        <Text>{u.score}%</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            ))}
          </View>
        </View>

        <View>
          {data.map((d) => (
            <View>
              <View style={{ marginBottom: 5 }}>
                <Text style={styles.subTitle}>{d.title}</Text>
              </View>
              <View>
                {d.users?.map((u) => {
                  return (
                    <View key={u.id}>
                      <Text
                        style={{
                          ...styles.user,
                          justifyContent: "flex-start",
                          borderLeft: `2px solid ${getBorderColor(d.title)}`,
                        }}
                      >
                        <Text>{u.name}: </Text>
                        <Text>{u.score}%</Text>
                      </Text>

                      <Text
                        style={{
                          margin: "10px 0",
                          fontSize: 10,
                          color: "white",
                        }}
                      >
                        {u.description}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
