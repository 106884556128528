export enum CompatibilityLevel {
  "SynergiesPartners",
  "SupportiveCollaborators",
  "GrowthAllies",
  "ConflictProne",
  "Yourself",
}

export type CompatibilityDetails = {
  id: number;
  name: string;
  score: number;
  description: string;
};
