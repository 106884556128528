import { FC, useEffect, useState } from "react";
import { CompatibilityDetails as CompatibilityDetailsType } from "../Compatibility.type";
import { CompatibilitySection } from "../CompatibilitySection/CompatibilitySection";
import { CompatibilityDescription } from "../CompatibilityDescription/CompatibilityDescription";

type Props = {
  users: CompatibilityDetailsType[];
  isLoadingCompatibility: boolean;
};

export const CompatibilityDetails: FC<Props> = ({
  users,
  isLoadingCompatibility,
}) => {
  const [selectedUser, setSelectedUser] = useState<
    CompatibilityDetailsType | undefined
  >(users[0]);

  useEffect(() => {
    setSelectedUser(users[0]);
  }, [users]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: 30,
        color: "white",
      }}
    >
      <CompatibilitySection
        users={users}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />

      <CompatibilityDescription
        name={selectedUser?.name || ""}
        description={selectedUser?.description}
        isLoading={isLoadingCompatibility}
      />
    </div>
  );
};
