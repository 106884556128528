import { FC } from "react";

type Props = {
  name: string | undefined;
  description: string | undefined;
  isLoading: boolean;
};

export const CompatibilityDescription: FC<Props> = ({
  name,
  description,
  isLoading,
}) => {
  return (
    <div
      style={{
        paddingLeft: 30,
        borderLeft: "2px solid #2d2e53",
      }}
    >
      <div style={{ marginBottom: 15, fontSize: 24 }}>{name || ""}</div>

      <div>
        {isLoading
          ? "Loading..."
          : description ||
            "Please, select a user from the Compatibility Matrix"}
      </div>
    </div>
  );
};
